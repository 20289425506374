import { Typography, Skeleton, Row, Col, Form, DatePicker, Select, Descriptions, Button, Input, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { BarChart, Bar, Cell, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import dayjs from "dayjs";
import moment from "moment";
import { toGetUsers } from "../Compte/Api";
import { toAddDivers, toGetCaisseData, toGetDivers, toPrintExercice, toPrintFiche, toRemoveDivers } from "./APIs";
import { successMsg, toConvertDate } from "../../Utils/Utils";
import Modal from "antd/lib/modal/Modal";
const { Option } = Select;

const Caisse = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(-1);
  const [form] = Form.useForm();
  const [formDivers] = Form.useForm();

  const [users, setUsers] = useState([]);
  const [divers, setDivers] = useState([]);
  const [diverOpen, setDiverOpen] = useState(false);

  useEffect(() => {
    formDivers.setFieldsValue({
      note: "",
      value: 0,
      type: "",
    });
    AppStore.setFirst(2);
    AppStore.setSecond(13);
    toGetUsers().then((users) => {
      setUsers(users);
      setUserId(users[0].id);
      getData(moment(date).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"), users[0].id);
      setLoading(false);
    });
  }, []);

  const onChangeUsers = (option) => {
    var id = users.filter((el) => el.username == option)[0].id;
    setUserId(id);
    getData(date, end, id);
  };

  const imprimerExercice = () => {
    setLoading(true);
    toPrintExercice("2023", userId).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  const getData = (date, end, l__userId) => {
    setLoading(true);
    setDate(moment(date).format("YYYY-MM-DD"));
    setEnd(moment(end).format("YYYY-MM-DD"));
    toGetDivers(moment(date).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD")).then((res) => {
      setDivers(res);
    });
    toGetCaisseData(moment(date).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"), l__userId).then((res) => {
      setData(res);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  const imprimer = () => {
    toPrintFiche(date, end, userId).then((res) => {});
  };

  const addDiver = () => {
    formDivers.validateFields().then((res) => {
      toAddDivers(res).then((res) => {
        successMsg("Divers ajoutées avec succées");
        getData(date, end, userId);
        setDiverOpen(false);
      });
    });
  };
  return (
    <>
      <Skeleton active loading={loading} />
      <Form form={form} style={{ marginTop: "2vh" }}>
        <Row className='auto--custom-header'>
          <Col span='4'>
            <Typography.Title level={3}>CAISSE</Typography.Title>
          </Col>
          <Col span='4'>
            <Form.Item label='Date'>
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  getData(date[0], date[1], userId);
                }}
              />
            </Form.Item>
          </Col>
          <Col span='4'>
            <Form.Item rules={[{ required: true, message: "Champ obligatoire" }]} span={8} label='Utilisateurs' name='user'>
              {users.length > 0 && (
                <Select showSearch onSelect={onChangeUsers} defaultValue={users.length > 0 && users[0].username}>
                  {users.map((u) => (
                    <Option value={u.username}>{u.username}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span='4'>
            <Button style={{ marginLeft: "20px" }} type='primary' onClick={() => imprimer()}>
              Imprimer la fiche de caisse
            </Button>
          </Col>
          {/* <Col span="4">
            <Button
              style={{ marginLeft: "20px" }}
              type="primary"
              onClick={() => imprimerExercice()}
            >
              Imprimer l'exercice
            </Button>
          </Col> */}
          <Col span='4'>
            <Button style={{ marginLeft: "20px" }} type='primary' onClick={() => setDiverOpen(true)}>
              Ajouter un divers
            </Button>
          </Col>
        </Row>
      </Form>
      {/* {data.length > 0 && (
        <Row>
          <Col span="24">
            <div style={{ height: 300 }}>
              <ResponsiveContainer>
                <BarChart
                  height={400}
                  data={data}
                  style={{ marginTop: "50px", marginBottom: "2vh" }}
                >
                  <XAxis dataKey="username" fontFamily="Proxima Nova"></XAxis>
                  <Tooltip cursor={{ fill: "transparent" }} />

                  <Bar
                    radius={[2, 2, 0, 0]}
                    dataKey="total"
                    barSize={200}
                    stackId="a"
                    fill="#846eb1"
                    fontFamily="Proxima Nova"
                  >
                    {data.map((entry, index) => (
                      <Cell></Cell>
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )} */}
      <Descriptions column={1} bordered justify='end' size='large' style={{ backgroundColor: "white", marginTop: "2vh" }}>
        <Descriptions.Item label='espèces' className='item-label'>
          {data
            .filter((el) => el.data.type == 0)
            .reduce((a, b) => a + parseFloat(b.data.montant), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='chèques' className='item-label'>
          {data
            .filter((el) => el.data.type == 1)
            .reduce((a, b) => a + parseFloat(b.data.montant), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='traites' className='item-label'>
          {data
            .filter((el) => el.data.type == 2)
            .reduce((a, b) => a + parseFloat(b.data.montant), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='Carte bancaire' className='item-label'>
          {data
            .filter((el) => el.data.type == 3)
            .reduce((a, b) => a + parseFloat(b.data.montant), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='Virement bancaire' className='item-label'>
          {data
            .filter((el) => el.data.type == 4)
            .reduce((a, b) => a + parseFloat(b.data.montant), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='retenu à la source' className='item-label'>
          {data
            .filter((el) => el.data.type == 5)
            .reduce((a, b) => a + parseFloat(b.data.montant), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='contre remoboursement' className='item-label'>
          {data
            .filter((el) => el.data.type == 6)
            .reduce((a, b) => a + parseFloat(b.data.montant), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='Actif' className='item-label'>
          {divers
            .filter((el) => el.type == 1)
            .reduce((a, b) => a + parseFloat(b.value), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='Passif' className='item-label'>
          {divers
            .filter((el) => el.type == 0)
            .reduce((a, b) => a + parseFloat(b.value), 0)
            .toFixed(3) + " DT"}
        </Descriptions.Item>
        <Descriptions.Item label='Total' className='item-label'>
          <b style={{ color: "red", fontSize: "20px" }}>
            {" "}
            {(
              data.reduce((a, b) => a + parseFloat(b.data.montant), 0) +
              divers.filter((el) => el.type == 1).reduce((a, b) => a + parseFloat(b.value), 0) -
              divers.filter((el) => el.type == 0).reduce((a, b) => a + parseFloat(b.value), 0)
            ).toFixed(3)}
          </b>
        </Descriptions.Item>
      </Descriptions>

      <div style={{ margin: "20px" }}>
        <b style={{ fontSize: "20px" }}>Divers</b>
      </div>
      <table className='ant-table' style={{ width: "100%", marginTop: "2vh" }}>
        <thead className='ant-table-thead'>
          <tr>
            <th className='ant-table-cell'>Date</th>
            <th className='ant-table-cell'>Note</th>
            <th className='ant-table-cell'>Type</th>
            <th className='ant-table-cell'>Valeur</th>
            <th className='ant-table-cell'></th>
          </tr>
        </thead>
        <tbody className='ant-table-tbody'>
          {divers.map((el) => (
            <tr>
              <td className='ant-table-cell'>{toConvertDate(el.dt__createdDate)}</td>
              <td className='ant-table-cell'>{el.note}</td>
              <td className='ant-table-cell'>{el.type ? "Actif" : "Passif"}</td>
              <td className='ant-table-cell'>{parseFloat(el.value).toFixed(3)}</td>
              <td>
                <Popconfirm
                  title={`Voulez vous vraiment supprimer ce divers?`}
                  onConfirm={() =>
                    toRemoveDivers(el.id).then((res) => {
                      getData(date, end, userId);
                    })
                  }
                  okText='Oui'
                  cancelText='Non'
                >
                  <Button style={{ marginRight: 10 }} icon={<DeleteOutlined />} />
                </Popconfirm>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ margin: "20px" }}>
        <b style={{ fontSize: "20px" }}>Détails</b>
      </div>
      <table className='ant-table' style={{ width: "100%", marginTop: "2vh" }}>
        <thead className='ant-table-thead'>
          <tr>
            <th className='ant-table-cell'>Date</th>
            <th className='ant-table-cell'>Référence BL/Facture</th>
            <th className='ant-table-cell'>Total BL/Facture</th>
            <th className='ant-table-cell'>Caisse</th>
          </tr>
        </thead>
        <tbody className='ant-table-tbody'>
          {data.map((el) => (
            <tr>
              <td className='ant-table-cell'>{toConvertDate(el.createdDate)}</td>
              <td className='ant-table-cell'>{el.ref}</td>
              <td className='ant-table-cell'>{parseFloat(el.data.montant).toFixed(3)}</td>
              <td className='ant-table-cell'>{parseFloat(el.data.montant).toFixed(3)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        title='Divers'
        visible={diverOpen}
        onCancel={() => setDiverOpen(false)}
        footer={[
          <Button type='primary' onClick={() => addDiver()}>
            Ajouter divers
          </Button>,
        ]}
      >
        <Form form={formDivers}>
          <Form.Item rules={[{ required: true, message: "Champ obligatoire" }]} label='Type' name='type'>
            <Select>
              <Select.Option value='actif'>Actif </Select.Option>
              <Select.Option value='passif'>Passif </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item rules={[{ required: true, message: "Champ obligatoire" }]} label='Value' name='value'>
            <Input type='number'></Input>
          </Form.Item>
          <Form.Item rules={[{ required: true, message: "Champ obligatoire" }]} label='Note' name='note'>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Caisse;
